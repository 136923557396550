import request from '@/api/request.js'



// 查询部门和部门下的教练 --- 树结构
export function selectCoachAndDeptTree(param) {
  return request({
    url: 'oa/org/selectCoachAndDeptTree',
    method: 'get',
    params: param
  })
}
// 查询部门 --- 树结构
export function selectDeptTree(param) {
  return request({
    url: 'oa/org/tree/selectDeptTree',
    method: 'get',
    params: param
  })
}
// 查询教练
export function selectCoach(param) {
  return request({
    url: 'oa/org/selectCoach',
    method: 'get',
    params: param
  })
}
// 查询角色
export function selectRole() {
  return request({
    url: 'oa/org/selectRole',
    method: 'get'
  })
}





// 查询教练  ------ 弃用 ---------
export function getUserByName(param) {
  return request({
    url: 'oa/org/tree/coach/selectCoach',
    method: 'get',
    params: param
  })
}
// 查询部门和部门下的教练 架构树   ------- 弃用-----------
export function getOrgTree(param) {
  return request({
    url: 'oa/org/selectCoachAndDeptTree',
    method: 'get',
    params: param
  })
}

export default {
  getOrgTree, getUserByName, selectRole
}
